export const environment = {
    // set if this is an production environment
    production: false,

    // set if this is an local environment
    local: false,

    // list of server urls to bypass authentication
    bypassAuthenticationURLs: ['users/login', 'https://autocomplete.geocoder.api.here.com', 'https://geocoder.api.here.com'],

    // moura connect server base URL
    mouraConnectServerURL: 'https://api-dev-iot.moura.com.br',

    // S3 bucket base URL
    storagebaseUrl: 'https://s3.amazonaws.com/moura-connect-api-dev',

    // idle/timeout configurations (seconds)
    idleTime: 10 * 60,
    idleTimeoutTime: 30,

    // criptography key
    cryptographicAESKey: ']HN+zjud93h!x,Z2',

    // Here's api app id
    hereAppId: 'nmts3dXmWYtNgmHkwQES',

    // Here's api app code
    hereAppCode: '8QF-0Ifvlru12SjgNfKAWA'
};
